<template>
  <img
    :src="'@/assets/images/logo/Recurso 1.png'"
    width="80px"
    alt=""
    srcset=""
  />
</template>

<script>
export default {
  data() {
    return {
      imageLogo: null,
    };
  },
};
</script>
